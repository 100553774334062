define("spotassist/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii", "spotassist/utils/global-error-handler", "spotassist/config/environment"], function (_exports, _torii, _globalErrorHandler, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    service
  } = Ember.inject;

  var _default = _torii.default.extend({
    torii: service('torii'),
    //currentUser: Ember.inject.service('current-user'),
    session: Ember.inject.service('session'),
    cookies: Ember.inject.service('cookies'),

    restore(data) {
      /*
      return new Promise((resolve, reject) => {
        if (!isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
       */
    },

    invalidate() {
      console.info('torii invalidate');
      return new Ember.RSVP.Promise(function (resolve) {
        resolve();
      });
    },

    authenticate(options) {
      var that = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        that._super(options).then(function (data) {
          var dataJson = null;

          if (options === 'facebook-connect') {
            dataJson = {
              'facebook_access_token': data.accessToken
            };
          }

          if (options === 'google-oauth2-bearer') {
            dataJson = {
              'google_access_token': data.authorizationToken.access_token
            };
          }

          if (dataJson) {
            /*
            return socialLogin.loginWithData(dataJson).then(function (user) {
              that.get('session').set('user', user)
            });
            */
            let csrftoken = that.get('cookies').read('csrftoken');
            Ember.$.ajax({
              type: "POST",
              headers: {
                "X-CSRFToken": csrftoken
              },
              url: _environment.default.adapter_host + '/users/request-token/',
              //url: ENV.adapter_host + '/users/atoken/',
              data: dataJson,
              dataType: 'json',
              success: function (user) {
                //resolve(user);
                resolve({
                  //token: response.token
                  token: user.token
                });
              },
              error: function (error) {
                var message = 'Unable to login user with ' + options + ' credentials error:' + error;
                console.error(message);
                (0, _globalErrorHandler.default)(error, message);
                reject();
              }
            });
          } else {
            console.warn('provider ' + options + ' not found.');
            reject();
          }
        }, function (error) {
          var message = 'torii authenticate:facebook-connect error:' + error;
          console.error(message);
          (0, _globalErrorHandler.default)(error, message);
          reject();
        });
      });
    }

  });

  _exports.default = _default;
});