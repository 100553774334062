define("spotassist/initializers/stripe", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    const application = arguments[1] || arguments[0];
    const {
      stripe = {}
    } = _environment.default;
    application.register('config:stripe', stripe, {
      instantiate: false
    });
    application.inject('service:stripe', 'stripeConfig', 'config:stripe');
  }

  var _default = {
    name: 'stripe',
    initialize
  };
  _exports.default = _default;
});