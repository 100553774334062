define("spotassist/utils/load-current-user", ["exports", "spotassist/config/environment", "spotassist/utils/global-error-handler", "spotassist/objects/user-acl"], function (_exports, _environment, _globalErrorHandler, _userAcl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadCurrentUser;

  function loadCurrentUser(session, store) {
    let cookieService = Ember.getOwner(session).lookup('service:cookies');
    let cookie_token = cookieService.read('csrftoken');
    Ember.$.ajax({
      type: "GET",
      headers: {
        "X-CSRFToken": cookie_token,
        'Authorization': 'Token ' + session.get('data.authenticated.token')
      },
      url: _environment.default.adapter_host + '/auth/users/me/',
      //dataType: 'json',
      success: function (user) {
        session.set('user', user);
      },
      error: function (error) {
        var message = 'Unable to get current user:' + error;
        console.error(message);
        (0, _globalErrorHandler.default)(error, message);
        session.invalidate();
      }
    });
    Ember.$.ajax({
      type: "GET",
      headers: {
        "X-CSRFToken": cookie_token,
        'Authorization': 'Token ' + session.get('data.authenticated.token')
      },
      url: _environment.default.adapter_host + '/me/permissions/',
      //dataType: 'json',
      success: function (permissions) {
        permissions.forEach(function (permission) {
          permission.object_id = "" + permission.object_id;
        });
        store.findAll('permission').then(function (all_permissions) {
          //that.set('permissions', permissions);
          var userAcl = _userAcl.default.create({
            permissions: permissions,
            all_permissions: all_permissions
          });

          session.set('userAcl', userAcl);
        });
      },
      error: function (error) {
        var message = 'Unable to get current user:' + error;
        console.error(message);
        (0, _globalErrorHandler.default)(error, message);
        session.invalidate();
      }
    });
  }
});