define("spotassist/objects/canopy-object", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Canopy = Ember.Object.extend({
    name: null,
    glideRatio: null,
    speed: null,

    init() {
      this._super();

      this.set('__interfaces', "com.livewings.spotassist.library.json.ICanopy");
    },

    //public int getCanopyId();
    getName() {
      return this.get('name');
    },

    getGlideRatio() {
      return this.get('glideRatio');
    },

    getSpeedKts() {
      return this.get('speed');
    },

    getSpeedMs() {
      return this.getSpeedKts() * _environment.default.CONSTANTS.KNOTS_TO_METERSPERSEC;
    }

  });
  var _default = Canopy;
  _exports.default = _default;
});