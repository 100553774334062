define("spotassist/components/rawinsonde-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    top: 5000,
    filterBy: 'flightlevel',
    filteredLevels: Ember.computed('filterBy', 'top', 'flightlevels', function () {
      var flightlevels = this.get('flightlevels');
      var top = this.get('top');
      return flightlevels.filter(function (flightlevel) {
        return flightlevel.height_m < top;
      });
    }),
    sortedLevels: Ember.computed.sort('filteredLevels', 'sortDefinition'),
    sortBy: 'height_m',
    reverseSort: false,
    sortDefinition: Ember.computed('sortBy', 'reverseSort', function () {
      let sortOrder = this.get('reverseSort') ? 'desc' : 'asc';
      return [`${this.get('sortBy')}:${sortOrder}`];
    })
  });

  _exports.default = _default;
});