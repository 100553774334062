define("spotassist/controllers/dropzones/index", ["exports", "spotassist/config/environment", "geolib", "spotassist/templates/typeahead-suggestion", "spotassist/templates/typeahead-not-found", "spotassist/utils/geocode-dropzone", "spotassist/utils/global-error-handler", "spotassist/mixins/requestable-weather", "spotassist/mixins/savable-dropzone"], function (_exports, _environment, _geolib, _typeaheadSuggestion, _typeaheadNotFound, _geocodeDropzone, _globalErrorHandler, _requestableWeather, _savableDropzone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import geolib from "npm:geolib";
  //import L from 'leaflet-draw';
  //import { v1} from "ember-uuid";

  /* global L */

  /* global google */
  const EMPTY_ARRAY = [];
  const GeoAddress = Ember.Object.extend({
    from_address: true,
    displayName: Ember.computed('dz_address', function () {
      return this.get('dz_address');
    }),
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    icon: Ember.computed(function () {
      return new L.icon({
        //iconUrl: 'assets/images/unregistered-dropzone.png',
        iconUrl: _environment.default.rootURL + 'assets/images/spotassist_web_logo25.png'
      });
    })
  });
  const CrowdDropzone = Ember.Object.extend({
    icon_type: 'crowd',
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    dz_outs_shapes: Ember.computed('dz_outs', function () {
      var shapes = [];
      this.get('dz_outs').forEach(function (dz_out) {
        var shape = JSON.parse(dz_out.shape_geojson);
        shapes.addObject(shape);
      });
      return shapes;
    }),
    icon: Ember.computed(function () {
      if (this.get('icon_type') === 'crowd') {
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/crowd-dropzone.png'
        });
      }

      if (this.get('icon_type') === 'around') {
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/around-dropzone.png'
        });
      }

      if (this.get('icon_type') === 'unregistered') {
        return new L.icon({
          //iconUrl: 'assets/images/unregistered-dropzone.png',
          iconUrl: _environment.default.rootURL + 'assets/images/spotassist_web_logo25.png'
        });
      }
    }),
    landingCorridorSet: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor') >= 0;
    }),
    landingCorridorIcon: Ember.computed(function () {
      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/crowd_corridor.png',
        iconAnchor: [27, 97],
        className: 'corridor-opacity'
      });
    }),
    rotation: Ember.computed('dz_landing_corridor', function () {
      return this.get('dz_landing_corridor');
    }),
    hasDirtyAttributes: Ember.computed(function () {
      return true;
    }),
    displayName: Ember.computed('dz_name', function () {
      return this.get('dz_name');
    })
  });

  var _default = Ember.Controller.extend(_requestableWeather.default, _savableDropzone.default, {
    rootURL: _environment.default.rootURL,
    tab: "info",
    //dropzones_service: inject('dropzones'),
    metrics: Ember.inject.service(),
    session: Ember.inject.service('session'),
    router: Ember.inject.service('-routing'),
    //user_settings: Ember.inject.service('user-settings'),
    satteliteMode: Ember.computed.readOnly('user-settings.satteliteMode'),
    crowdDropzonesOn: Ember.computed.readOnly('user-settings.crowdDropzonesOn'),
    registeredDropzonesOn: Ember.computed.readOnly('user-settings.registeredDropzonesOn'),
    allRaobsOn: Ember.computed.readOnly('user-settings.allRaobsOn'),
    allOpenweatherOn: Ember.computed.readOnly('user-settings.allOpenweatherOn'),
    radiusWeatherStationsOn: Ember.computed.readOnly('user-settings.radiusWeatherStationsOn'),
    //dropzones: computed.readOnly('dropzones_service.dropzones'),
    //loadingMap: computed.readOnly('dropzones_service.loadingDropzones'),
    //loadingMapError: computed.readOnly('dropzones_service.loadingDropzonesError'),
    loadingMap: Ember.computed('dropzones_service.loadingDropzones', 'loadingCrowdMap', function () {
      return this.get('dropzones_service.loadingDropzones') || this.get('loadingCrowdMap');
    }),
    loadingMapError: Ember.computed('dropzones_service.loadingDropzonesError', 'loadingCrowdMapError', function () {
      return this.get('dropzones_service.loadingDropzonesError') || this.get('loadingCrowdMapError');
    }),
    googleStreets: L.tileLayer('//{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    googleHybrid: L.tileLayer('//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    googleSat: L.tileLayer('//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    googleTerrain: L.tileLayer('//{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    queryParams: ['lat', 'lon', 'zoom', 'token', 'crowd', 'verify_dz_id', 'layout', "select_dz_id"],
    lat: 20,
    lon: 10,
    zoom: 3,
    token: null,
    crowd: null,
    verify_dz_id: null,
    select_dz_id: null,
    layout: null,
    customSuggestionTemplate: _typeaheadSuggestion.default,
    customNotFoundTemplate: _typeaheadNotFound.default,
    unregisteredDropzones: EMPTY_ARRAY,
    //registeredDropzones: [],
    registeredDropzones: Ember.computed('dropzones_service.dropzones', function () {
      //return this.store.peekAll('dropzone');
      return this.get('dropzones_service.dropzones') ? this.get('dropzones_service.dropzones') : [];
    }),
    activeRegisteredDropzones: Ember.computed('registeredDropzones.{@each.active,@each.id}', function () {
      return this.get('registeredDropzones').filter(function (record) {
        var active = record.get('active');
        return active;
      });
    }),
    selectedDropzoneUnselector: Ember.observer('activeRegisteredDropzones', function () {
      this.set('selectedDropzone', null);
    }),

    /*
    userDropzones: computed('', function() {
      //return this.store.peekAll('user-dropzone');
      return [];
    }),
    */

    /*
      dropzones: computed(function() {
        return this.get('store').peekAll('dropzone');
      }),
      rawinsondes: computed(function() {
        return this.get('store').peekAll('raob-station');
      }),
    */
    init() {
      this._super();

      this.set("user-settings.registeredDropzonesOn", true);
    },

    setRouteActive(routeActive) {
      this.set('routeActive', routeActive);

      if (routeActive) {
        var that = this;
        Ember.run.scheduleOnce('afterRender', this, () => {
          if (this.get('select_dz_id')) {
            var dropzone = this.store.peekRecord('dropzone', this.get('select_dz_id'));
            that.set('selectedDropzone', dropzone);
          }
        });
        /*
         that.store.findRecord('dropzone', that.get('select_dz_id'))
          .then(function (selectionDropzone) {
            //that.set('selectedDropzone', null);
             scheduleOnce('afterRender', this, () => {
              that.set('selectedDropzone', selectionDropzone);
            });
          });
          */
      } else {
        this.set('selectedDropzone', null);
      }
    },

    postLoadDropzoneSelector: Ember.observer('dropzones_service.loadingDropzones', function () {
      var loaded = !this.get('dropzones_service.loadingDropzones');

      if (loaded && this.get('select_dz_id')) {
        var that = this;
        that.store.findRecord('dropzone', that.get('select_dz_id')).then(function (selectionDropzone) {
          //that.set('selectedDropzone', null);
          that.set('selectedDropzone', selectionDropzone);
        });
      }
    }),
    tokenSetter: Ember.observer('token', function () {
      this.get('user-settings').set('temporary_token', this.get('token'));
    }),
    crowdModeSetter: Ember.observer('crowd', function () {
      this.get('user-settings').set('crowdDropzonesOn', this.get('crowd'));
    }),

    getDisplayKey(model) {
      return Ember.get(model, 'dz_name');
    },

    satteliteModeSwitcher: Ember.observer('satteliteMode', function () {
      this.setupSatelliteMode();
    }),

    setupSatelliteMode() {
      if (this.get('satteliteMode')) {
        this.get('map').removeLayer(this.googleStreets);
        this.get('map').addLayer(this.googleHybrid);
      } else {
        this.get('map').removeLayer(this.googleHybrid);
        this.get('map').addLayer(this.googleStreets);
      }
    },

    requestCrowdUnregisteredDropzones(show) {
      if (!show) {
        return;
      }

      if (this.store.peekAll('crowd-user-dropzone').content.length > 0) {
        return;
      }

      var that = this;
      this.set('loadingCrowdMap', true); //this.store.findAll('crowd-user-dropzone', {unregistered: "true"}).then(function(dropzones) {

      var query = {
        unregistered: "true"
      };

      if (this.get('verify_dz_id')) {
        query = {
          verify_dz_id: this.get('verify_dz_id')
        };
      }

      this.store.query('crowd-user-dropzone', query).then(function (dropzones) {
        that.set('loadingCrowdMap', false);

        if (that.get('verify_dz_id')) {
          var userDropzone = dropzones.findBy('id', that.get('verify_dz_id'));

          if (userDropzone) {
            userDropzone.set('overrideDigested', false);
          }

          that.set('selectedDropzone', userDropzone);
          that.set('dropzoneEditMode', true);
        }

        that.set('unregisteredDropzones', dropzones);
      }, function (error) {
        var message = "Error loading store: crowd-user-dropzone";
        console.error(message + error);
        (0, _globalErrorHandler.default)(error, message);
        that.set('loadingCrowdMap', false);
        that.set('loadingCrowdMapError', true);
      });
      /*
      var unregisteredDropzonesUrl = ENV.adapter_host+"/crowd-user-dropzones/?unregistered=true";
      Ember.$.ajax(unregisteredDropzonesUrl).then(function(dropzones) {
        that.set('crowdLoadingProgress', false);
        that.set('crowdLoadingError', false);
         var unregisteredDropzones = [];
        dropzones.forEach(function(dropzone) {
            dropzone.icon_type = 'unregistered';
            var crowdDropzone = CrowdDropzone.create(dropzone);
            unregisteredDropzones.push(crowdDropzone);
        });
         that.get('unregisteredDropzones').addObjects(unregisteredDropzones);
       }, function(error) {
        that.set('crowdLoadingProgress', false);
        that.set('crowdLoadingError', true);
      });
      */
    },

    requestDropzoneAroundDropzones(masterDropzone, show) {
      if (!show) {
        masterDropzone.set('aroundDropzones', []);
        return;
      } //load dropzones around


      var lat = masterDropzone.get('lat');
      var lon = masterDropzone.get('lon');
      var that = this;
      var radiusDropzonesUrl = _environment.default.adapter_host + "/crowd-user-dropzones/?lat=" + lat + "&lon=" + lon + "&radius=5";
      that.set('crowdLoadingProgress', true);
      Ember.$.ajax(radiusDropzonesUrl).then(function (dropzones) {
        that.set('crowdLoadingProgress', false);
        that.set('crowdLoadingError', false);
        var aroundDropzones = [];
        dropzones.forEach(function (dropzone) {
          if (dropzone.dz_id + '' !== masterDropzone.get('id')) {
            dropzone.icon_type = 'around';
            var crowdDropzone = CrowdDropzone.create(dropzone);
            aroundDropzones.push(crowdDropzone);
          }
          /*
          if(dropzone.id !== that.get('selectedDropzone').get('id') &&
            //!that.get('unregisteredDropzones').findBy('id', dropzone.id)) {
              !that.store.peekRecord('crowd-user-dropzone', dropzone.id)) {
            if(dropzone.dz_id !== masterDropzone.get('id')) {
              dropzone.icon_type = 'around';
              var crowdDropzone = CrowdDropzone.create(dropzone);
              aroundDropzones.push(crowdDropzone);
            }
          }
          */

        });
        masterDropzone.set('aroundDropzones', aroundDropzones);
      }, function (error) {
        var message = "Error loading url: " + radiusDropzonesUrl;
        console.error(message + error);
        (0, _globalErrorHandler.default)(error, message);
        that.set('crowdLoadingProgress', false);
        that.set('crowdLoadingError', true);
      });
    },

    requestDropzoneCrowdDropzones(masterDropzone, show) {
      if (!show) {
        masterDropzone.set('crowdDropzones', []);
        return;
      }

      if (masterDropzone.constructor.modelName !== 'dropzone') {
        return;
      }

      var that = this;
      var crowdDropzonesUrl = _environment.default.adapter_host + "/crowd-user-dropzones/?dz_id=" + masterDropzone.get('id');
      that.set('crowdLoadingProgress', true);
      Ember.$.ajax(crowdDropzonesUrl).then(function (dropzones) {
        that.set('crowdLoadingProgress', false);
        that.set('crowdLoadingError', false);
        var crowdDropzones = [];
        dropzones.forEach(function (dropzone) {
          var crowdDropzone = CrowdDropzone.create(dropzone);
          crowdDropzones.push(crowdDropzone);
        });
        masterDropzone.set('crowdDropzones', crowdDropzones);
      }, function (error) {
        var message = "Error loading url: " + crowdDropzonesUrl;
        console.error(message + error);
        (0, _globalErrorHandler.default)(error, message);
        that.set('crowdLoadingProgress', false);
        that.set('crowdLoadingError', true);
      });
    },

    removeOutsideVisibleBounds(value) {
      var map = this.get('map');
      map.eachLayer(function (layer) {
        //layer.bindPopup('Hello');
        //console.log(""+layer.name);
        if (layer.options) {
          layer.options.removeOutsideVisibleBounds = value;
        }
      });
    },

    stationsStatusLoader: Ember.observer('selectedDropzone', function () {
      var dropzone = this.get('selectedDropzone');

      if (dropzone) {
        var query = {
          lat: dropzone.get('lat'),
          lon: dropzone.get('lon'),
          radius: 100
        };
        var that = this;
        this.store.query('raob-station', query).then(function (rawinsondes) {
          that.set('rawinsondes', rawinsondes);
        });
        this.store.query('openweather-station', query).then(function (openweather) {
          that.set('openweather', openweather);
        });
      } else {
        this.set('rawinsondes', []);
        this.set('openweather', []);
      }
    }),
    manualDropzoneMover: Ember.observer('selectedDropzone.lon', function () {
      var dropzone = this.get('selectedDropzone'); //if(!this.get('markerClicked') && dropzone) {

      if (dropzone) {
        //disable cluster
        //this.removeOutsideVisibleBounds(false);
        this.doZoomToDropzone(dropzone); //enable cluster
        //this.removeOutsideVisibleBounds(true);
      }
    }),
    selectedDropzoneMapMover: Ember.observer('selectedDropzone', function () {
      this.get('user-settings').closeNav();
      this.processModelSelected();
      var dropzone = this.get('selectedDropzone');

      if (!dropzone) {
        this.set('dropzoneEditMode', false);
      }

      this.set('updateDropzoneProgressListener', null);

      if (!this.get('markerClicked') && dropzone) {
        this.doZoomToDropzone(dropzone);
      }

      if (dropzone && !dropzone.get('manual')) {
        this.requestDropzoneWeather(dropzone);
      }
    }),
    //crowdDropzonesLoader: observer('selectedDropzone', 'session.isAuthenticated', function() {
    crowdDropzonesLoader: Ember.observer('selectedDropzone', 'crowdDropzonesOn', function () {
      var dropzone = this.get('selectedDropzone');

      if (dropzone) {
        //var session = this.get('session');
        //if(session.get('isAuthenticated') && session.get('user') && session.get('user').is_superuser) {
        this.requestDropzoneCrowdDropzones(dropzone, this.get('crowdDropzonesOn'));
        this.requestDropzoneAroundDropzones(dropzone, this.get('crowdDropzonesOn')); //}
      }

      this.requestCrowdUnregisteredDropzones(this.get('crowdDropzonesOn'));
    }),
    allRaobsLoader: Ember.observer('allRaobsOn', function () {
      if (this.get('allRaobsOn')) {
        this.loadRaobs();
      } else {
        this.unloadRaobs();
      }
    }),
    allOpenweatherLoader: Ember.observer('allOpenweatherOn', function () {
      if (this.get('allOpenweatherOn')) {
        this.loadOpenweather();
      } else {
        this.unloadOpenweather();
      }
    }),
    radiusWeatherStationsLoader: Ember.observer('radiusWeatherStationsOn', 'selectedDropzone', function () {
      if (this.get('radiusWeatherStationsOn') && this.get("selectedDropzone")) {
        this.loadRaobs(this.get("selectedDropzone.lat"), this.get("selectedDropzone.lon"));
        this.loadOpenweather(this.get("selectedDropzone.lat"), this.get("selectedDropzone.lon"));
      } else {
        this.unloadRaobs();
        this.unloadOpenweather();
      }
    }),

    /*
    selectedDropzoneCorridorSetter: observer('selectedDropzone', function() {
      if(!this.get('selectedDropzone')) {
        this.set('selectedDropzoneCorridor', null);
      } else if(this.get('selectedDropzone') && this.get('selectedDropzone.landingCorridorSet')) {
        var corridor = LandingCorridor.create({
          dropzone: this.get('selectedDropzone')
        });
        this.set('selectedDropzoneCorridor', corridor);
      }
    }),
    */
    selectedDropzoneEditModeSetter: Ember.observer('dropzoneEditMode', function () {
      //console.log('dropzoneEditModeChanged:'+this.get('dropzoneEditMode'));
      var selectedDropzone = this.get('selectedDropzone');

      if (selectedDropzone) {
        //var marker = this.get('selectedMarker');
        var map = this.get('map');

        if (map) {
          if (this.get('dropzoneEditMode')) {
            this.doZoomToDropzone(selectedDropzone);
            /*
            L.popup({
                closeButton:false,
                offset: L.point(0,-37)
            })
            .setLatLng(L.latLng( selectedDropzone.get('lat'), selectedDropzone.get('lon') ))
            .setContent("Drag to position marker")
            .openOn(map);
            */
          } else {
            map.closePopup(); //this.requestDropzoneWeather(selectedDropzone);
          }
        }

        selectedDropzone.set('editMode', this.get('dropzoneEditMode'));
      }
    }),
    processModelSelected: function () {
      var selectedDropzone = this.get('selectedDropzone');
      var oldselectedDropzone = this.get('_oldselectedDropzone');

      if (selectedDropzone === oldselectedDropzone) {
        return;
      } // Do stuff here


      if (oldselectedDropzone) {
        //console.log('oldselectedDropzone:'+oldselectedDropzone.get('dz_name'));
        oldselectedDropzone.set('selected', false);
        oldselectedDropzone.set('editMode', false);
      }

      if (selectedDropzone) {
        //console.log('selectedDropzone:'+selectedDropzone.get('dz_name'));
        selectedDropzone.set('selected', true);
        selectedDropzone.set('editMode', this.get('dropzoneEditMode'));
      }

      this.set('_oldselectedDropzone', selectedDropzone);
    },

    doZoomToDropzone()
    /*dropzone*/
    {
      //console.log("doZoomToDropzone");
      var map = this.get('map');

      if (map) {
        /*
        if(map.getZoom()<17) {
          //map.setView(L.latLng(dropzone.get('lat'), dropzone.get('lon')), 17);
         } else {
          map.setView(L.latLng(dropzone.get('lat'), dropzone.get('lon')));
        }
        */
        this.beautifyDropzoneModeScreen(); //this.get('user-settings').set('satteliteMode', true);
      }
    },

    tabsLayout: Ember.computed(function () {
      var layout = this.get('layout');

      if (layout === 'tabs') {
        return true;
      }
    }),
    flowLayut: Ember.computed('media.isMobile', function () {
      var layout = this.get('layout');

      if (layout === 'tabs') {
        return false;
      }

      if (this.get('media.isMobile')) {
        return true;
      }
    }),
    infoTabSelected: Ember.computed('tab', 'flowLayut', function () {
      if (this.get('flowLayut')) {
        return true;
      }

      return this.get('tab') === 'info';
    }),
    weatherTabSelected: Ember.computed('tab', 'flowLayut', function () {
      if (this.get('flowLayut')) {
        return true;
      }

      return this.get('tab') === 'weather';
    }),
    mapTabSelected: Ember.computed('tab', 'flowLayut', function () {
      if (this.get('flowLayut')) {
        return true;
      }

      return this.get('tab') === 'map';
    }),
    weatherReloader: Ember.observer('localSettings.settings.temperature_unit', 'localSettings.settings.speed_unit', 'localSettings.settings.altitude_unit', 'localSettings.settings.distance_unit', function () {//console.log('settings changed');

      /*
      var dropzone = this.get('selectedDropzone');
      if(dropzone) {
        this.requestDropzoneWeather(dropzone);
      }
      */
    }),

    loadRaobs(lat, lon) {
      var that = this;

      var success = function (rawinsondes) {
        that.set('loadingCrowdMap', false);
        that.set('rawinsondes', rawinsondes);
      };

      var error = function (error) {
        var message = "Error loading store: raob-station";
        console.error(+message + error);
        (0, _globalErrorHandler.default)(error, message);
        that.set('loadingCrowdMap', false);
        that.set('loadingCrowdMapError', true);
      };

      if (lat && lon) {
        that.set('loadingCrowdMap', true);
        var params = {
          reload: true,
          lat: lat,
          lon: lon,
          radius: 100
        };
        this.get('store').query('raob-station', params).then(success, error);
      } else {
        that.set('loadingCrowdMap', true);
        this.get('store').findAll('raob-station', {
          reload: true
        }).then(success, error);
      }
    },

    unloadRaobs() {
      this.set('rawinsondes', []);
    },

    loadOpenweather(lat, lon) {
      var that = this;

      var success = function (openweather) {
        that.set('loadingCrowdMap', false);
        that.set('openweather', openweather);
      };

      var error = function (error) {
        var message = "Error loading store: openweather-station";
        console.error(+message + error);
        (0, _globalErrorHandler.default)(error, message);
        that.set('loadingCrowdMap', false);
        that.set('loadingCrowdMapError', true);
      };

      if (lat && lon) {
        var params = {
          reload: true,
          lat: lat,
          lon: lon,
          radius: 100
        };
        this.get('store').query('openweather-station', params).then(success, error);
      } else {
        that.set('loadingCrowdMap', true);
        this.get('store').findAll('openweather-station', {
          reload: true
        }).then(success, error);
      }
    },

    unloadOpenweather() {
      this.set('openweather', []);
    },

    modeScreenBeautifier: Ember.observer('selectedDropzone', 'rawinsondes', 'openweather', function () {
      this.beautifyDropzoneModeScreen();
    }),

    beautifyDropzoneModeScreen() {
      var locations = [];
      var dropzone = this.get('selectedDropzone');

      if (dropzone) {
        locations.pushObject([dropzone.get('lat'), dropzone.get('lon')]);
        dropzone.get('dz_outs_shapes').forEach(function (shape) {
          shape.geometry.coordinates.forEach(function (coordinates) {
            coordinates.forEach(function (coordinate) {
              locations.pushObject([coordinate[1], coordinate[0]]);
            });
          });
        });
      }

      if (this.get('radiusWeatherStationsOn')) {
        if (this.get('rawinsondes')) {
          this.get('rawinsondes').forEach(function (rawinsonde) {
            locations.pushObject([rawinsonde.get('lat'), rawinsonde.get('lon')]);
          });
        }

        if (this.get('openweather')) {
          this.get('openweather').forEach(function (openweatherStation) {
            locations.pushObject([openweatherStation.get('lat'), openweatherStation.get('lon')]);
          });
        }
      }

      if (locations.length > 0) {
        var map = this.get('map');
        var padding = {};

        if (!this.get('media.isMobile')) {
          padding = {
            paddingTopLeft: L.point(540, 50),
            maxZoom: 17
          };
        }

        try {
          map.fitBounds(locations, padding);

          if (map.getZoom() >= 14) {
            this.get('user-settings').set('satteliteMode', true);
          } else {
            this.get('user-settings').set('satteliteMode', false);
          }
        } catch (exception) {
          console.warn("exception durung beautifyModeScreen. Beautification will retry");
        }
      }
    },

    actions: {
      /*
      loading(transition, originRoute) {
       },
      */
      callRaobWeatherUpdate(raobStationId) {
        console.info("callRaobWeatherUpdate for stationId=" + raobStationId);
        this.store.findRecord('raob-station', raobStationId).then(function (raobStation) {
          raobStation.set('updatingWeather', true);
          var updateWeatherUrl = _environment.default.adapter_host + "/raob-stations/" + raobStationId + "/update-weather";
          Ember.$.ajax(updateWeatherUrl).then(function (updatedRaobStation) {
            raobStation.set('updatingWeather', false);
            raobStation.set('valid', updatedRaobStation[0].valid);
            raobStation.set('updated', updatedRaobStation[0].updated);
          });
        });
      },

      callOpenweatherWeatherUpdate(openweatherStationId) {
        console.info("callOpenweatherWeatherUpdate for stationId=" + openweatherStationId);
        this.store.findRecord('openweather-station', openweatherStationId).then(function (openweatherStation) {
          openweatherStation.set('updatingWeather', true);
          var updateWeatherUrl = _environment.default.adapter_host + "/openweather-stations/" + openweatherStationId + "/update-weather";
          Ember.$.ajax(updateWeatherUrl).then(function (updatedRaobStation) {
            openweatherStation.set('updatingWeather', false);
            openweatherStation.set('download_time', updatedRaobStation[0].download_time);
          });
        });
      },

      searchAsync(query) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          if (query.length < 2) {
            resolve([]);
          } else {
            var dropzones = _this.get('activeRegisteredDropzones');

            var results = dropzones.filter(function (record) {
              var dz_name = record.get('dz_name');
              return dz_name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            });

            if (results.length <= 0) {
              var geocoder = new google.maps.Geocoder();
              geocoder.geocode({
                "address": query
              }, function (geoResults, status) {
                var addressResult = null;

                if (status === google.maps.GeocoderStatus.OK && geoResults.length > 0) {
                  var geoResult = geoResults[0];
                  addressResult = GeoAddress.create({
                    dz_address: geoResult.formatted_address,
                    dz_name: "Skydive " + geoResult.formatted_address,
                    lat: geoResult.geometry.location.lat(),
                    lon: geoResult.geometry.location.lng()
                  });
                  var geoResultLatLng = {
                    latitude: geoResult.geometry.location.lat(),
                    longitude: geoResult.geometry.location.lng()
                  };
                  results = dropzones.filter(function (record) {
                    var dzLatLng = {
                      latitude: record.get('lat'),
                      longitude: record.get('lon')
                    };
                    var inside = (0, _geolib.isPointInCircle)(geoResultLatLng, dzLatLng, 200000);
                    return inside;
                  });
                }

                if (addressResult) {} //results.unshift(addressResult);
                //results = results.splice(0, 0, addressResult);
                //results.push(addressResult);
                //results.push(addressResult);


                resolve(results);
              });
            } else {
              resolve(results);
            }
          }
        });
      },

      createNewDropzone() {
        Ember.get(this, 'metrics').trackEvent({
          event: "Create Dropzone"
        });
        var newDropzone = null;
        var defaultDzParams = {
          dz_name: null,
          lat: 23.103835,
          lon: -82.437418,
          active: 1
        };
        /*
        if(session.get('user') && session.get('user').is_superuser) {
            newDropzone = this.store.createRecord('dropzone', defaultDzParams);
        } else {
          newDropzone =  this.store.createRecord('user-dropzone', defaultDzParams);
        }
        */

        newDropzone = this.store.createRecord('dropzone', defaultDzParams);
        newDropzone.set('icon_type', 'unregistered');
        this.get('dropzones_service.dropzones').addObject(newDropzone);
        this.set('selectedDropzone', newDropzone);
        this.set('dropzoneEditMode', true);
      },

      markerClicked(dropzone, event) {
        //console.log('markerClicked: dz id:'+event.target.options.alt);
        Ember.get(this, 'metrics').trackEvent({
          event: "Click Marker",
          dropzone: dropzone.get('displayName'),
          editMode: this.get('dropzoneEditMode')
        });

        if (this.get('dropzoneEditMode')) {
          return;
        }

        var selectedDropzone = dropzone; //var selectedDropzone = this.store.peekRecord('dropzone', event.target.options.alt);

        if (!selectedDropzone) {
          selectedDropzone = this.get('unregisteredDropzones').findBy('id', event.target.options.alt);
        }

        this.set('markerClicked', true);
        this.set('selectedDropzone', selectedDropzone);
      },

      editDropzoneAction(dropzone) {
        Ember.get(this, 'metrics').trackEvent({
          event: "Edit Dropzone",
          dropzone: dropzone.get('displayName')
        });
        this.set('dropzoneEditMode', true);
      },

      deleteDropzoneAction(dropzone) {
        //console.log('deleteDropzone');
        Ember.get(this, 'metrics').trackEvent({
          event: "Delete Dropzone",
          dropzone: dropzone.get('displayName')
        });
        dropzone.set('active', 0);
        dropzone.save();
      },

      geocodeDropzoneAction(dropzone) {
        //console.log('geocodeDropzoneAction');
        Ember.get(this, 'metrics').trackEvent({
          event: "Geocode Dropzone",
          dropzone: dropzone.get('displayName')
        });
        (0, _geocodeDropzone.default)(dropzone);
      },

      selectDzTabAction(tabName) {
        this.set('tab', tabName);
      },

      loadSidebar(map) {
        L.control.zoom({
          position: 'bottomright'
        }).addTo(map.target); //L.control.layers(null, overlayPane, {position: 'topleft'}).addTo(map.target);c
        //workaround for draggable

        map.target.eachLayer(function (layer) {
          //layer.bindPopup('Hello');
          //console.log(""+layer.name);
          if (layer.options) {
            layer.options.removeOutsideVisibleBounds = false;
          }
        });
        this.set('map', map.target);
        map.target.addLayer(this.googleStreets);
        this.setupSatelliteMode();

        if (this.get('zoom') >= 14) {
          this.get('user-settings').set('satteliteMode', true);
        } //{{tile-layer url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"}}

      },

      zoomToDropzoneAction(dropzone) {
        this.doZoomToDropzone(dropzone);
      },

      unselectDropzoneAction() {
        //unselectDropzoneAction(dropzone) {
        this.set('selectedDropzone', null);
      },

      selectDropzoneAction(dropzone) {
        //console.log('selectDropzone action fired:'+dropzone);
        if (!dropzone) {
          return;
        }

        Ember.get(this, 'metrics').trackEvent({
          event: "Select Dropzone",
          dropzone: dropzone.get('displayName')
        });

        if (this.get('selectedDropzone') && this.get('selectedDropzone') !== dropzone) {
          this.doCancelEditDropzone(this.get('selectedDropzone'));
          var map = this.get('map');
          var selectedDropzone = this.get('selectedDropzone');
          map.setView(L.latLng(selectedDropzone.get('lat'), selectedDropzone.get('lon')), 10);
        } //check if dropzone needs to be created


        var session = this.get('session');
        var iconType = session.get('user') && session.get('user').is_superuser ? null : "unregistered";

        if (dropzone && dropzone.get('from_address')) {
          dropzone = this.store.createRecord('dropzone', {
            icon_type: iconType,
            dz_name: dropzone.get('dz_name'),
            dz_address: dropzone.get('dz_address'),
            lat: dropzone.get('lat'),
            lon: dropzone.get('lon'),
            active: 1
          });
          dropzone.set('manual', true);
          this.set('dropzoneEditMode', true);
        }

        this.set('markerClicked', false);
        this.set('selectedDropzone', dropzone);
      },

      layerControlEvent(event) {
        if (event.name === 'Rawinsodes') {
          this.loadRaobs();
        }

        if (event.name === 'New User Dropzones') {} //console.log('will load New User Dropzones');

        /*
        if(event.name=='Unlocated Dropzones') {
          var unloc_dropzones = this.get('store').peekAll('dropzone', {null_location: "true"});
          if(unloc_dropzones.get('content.length')<=0) {
            console.log('will load unloc-dropzones');
            var that = this;
            that.set('loadingCrowdMap', true);
            this.get('store').findAll('dropzone', {null_location: "true"}).then(function(unloc_dropzones) {
              that.set('loadingCrowdMap', false);
              that.set('unloc-dropzones', unloc_dropzones);
            }, function(error) {
              that.set('loadingCrowdMap', false);
              that.set('loadingCrowdMapError', true);
            });
          }
        }
        */

      }

    }
  });

  _exports.default = _default;
});