define("spotassist/components/dropzone-item-form-bigpicture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      createOnEnter(select, e) {
        //select.createOnEnter(select, e);
        if (e.keyCode === 13 && select.isOpen && !select.highlighted && !Ember.isBlank(select.searchText)) {
          //let selected = this.get('selected');
          let selected = select.selected;

          if (!selected || !selected.includes(select.searchText)) {
            let options = select.options;
            options.pushObject(select.searchText);
            select.actions.choose(select.searchText);
          }
        }
      },

      resetLandingCorridor() {
        this.get('item').set('dz_landing_corridor', -1);
      },

      saveDropzoneAction(dropzone) {
        //console.log('saveDropzoneAction');
        this.get('saveDropzoneAction')(dropzone);
      },

      cancelEditDropzoneAction(dropzone) {
        this.get('cancelEditDropzoneAction')(dropzone);
      },

      deleteDropzoneAction(dropzone) {
        //console.log('deleteDropzoneAction');
        this.get('deleteDropzoneAction')(dropzone);
      },

      geocodeDropzoneAction(dropzone) {
        this.get('geocodeDropzoneAction')(dropzone);
      },

      setValue(name, value) {
        //this.sendAction('setDzName', name);
        this.get('item').set(name, value);
      },

      setValueFromParam(name, param, value) {
        this.get('item').set(name, value[param]);
      }

    },

    init() {
      this._super();
    },

    didInsertElement() {
      this._super(...arguments);

      this.get('item').set('selected', true);
      this.get('item').set('editMode', true);
    },

    willDestroyElement() {
      this.get('item').set('selected', false);
      this.get('item').set('editMode', false);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});