define("spotassist/routes/auth/signup", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    router: Ember.inject.service(),

    /*
    beforeModel: function(transition) {
      var currentRouteName = this.controllerFor('application').get('currentRouteName');
      if (!this.get('session').get('isAuthenticated')) {
        this.set('session.lastUnauthenticatedRoute', currentRouteName);
        if(this.get('router').router.currentHandlerInfos) {
          var currentRouteParams = this.get('router').router.currentHandlerInfos.findBy('name', currentRouteName).params;
          this.set('session.lastUnauthenticatedRouteParams', currentRouteParams);
        }
      }
       this._super(transition);
      //this.set("user-settings.hide-app-menu", true);
      //this.get('user-settings').set('nav-bar-map-extension', false);
    },
    */
    sessionInvalidated() {//this.set('session.user', null);
      //Ember.get(this, 'metrics').trackEvent({event: 'Logout'});
    },

    sessionAuthenticated() {
      /*
      if(this.get('session.lastUnauthenticatedRoute')) {
        this.transitionTo(this.get('session.lastUnauthenticatedRoute'));
      }
      */
      //this.transitionTo('registered');
    }

  });

  _exports.default = _default;
});