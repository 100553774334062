define("spotassist/authenticators/drf-token-authenticator", ["exports", "ember-simple-auth/authenticators/base", "spotassist/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    restore(data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    authenticate(data) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        Ember.$.post(_environment.default.adapter_host + '/users/login/', data).then(function (response) {
          Ember.run(function () {
            resolve({
              //token: response.token
              token: response.auth_token
            });
          });
        }, function (error) {
          //if (error.status === 401) {
          Ember.run(function () {
            reject(error);
          }); //}
        });
      });
    }

  });

  _exports.default = _default;
});