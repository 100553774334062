define("spotassist/objects/dropzone-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import ENV from "spotassist/config/environment";

  /* global com */
  const Dropzone = Ember.Object.extend({
    dropzone: null,

    init() {
      this._super();

      this.set('__interfaces', "com.livewings.spotassist.library.json.IDropzone"); //this.viewSwitcher();
    },

    getTargetLatLng() {
      var lat = parseFloat(this.get('dropzone.lat'));
      var lon = parseFloat(this.get('dropzone.lon'));
      return new com.livewings.spotassist.library.crossdata.MapPoint(lat, lon);
    },

    setTargetLatLng() {//setTargetLatLng(target) {
    },

    getDz_id() {
      return this.get('dropzone.dz_id');
    },

    getDz_name() {
      return this.get('dropzone.dz_name');
    },

    getDz_ident() {
      return this.get('dropzone.dz_ident');
    },

    getDz_location() {
      return this.get('dropzone.dz_location');
    },

    getDz_address() {
      return this.get('dropzone.dz_address');
    },

    getDz_phone() {
      return this.get('dropzone.dz_phone');
    },

    getDz_email() {
      return this.get('dropzone.dz_email');
    },

    getDz_url() {
      return this.get('dropzone.dz_url');
    },

    getDz_elevation_m() {
      return this.get('dropzone.dz_elevation_m');
    },

    isManual() {
      return false;
    },

    getDz_landing_corridor() {
      return this.get('dropzone.dz_landing_corridor');
    },

    setDz_landing_corridor() {},

    getDz_pattern_strategy() {
      return this.get('dropzone.dz_pattern_strategy');
    },

    setDz_pattern_strategy() {},

    getDz_downwind_ft() {
      return this.get('dropzone.dz_downwind_ft');
    },

    setDz_downwind_ft() {},

    getDz_base_ft() {
      return this.get('dropzone.dz_base_ft');
    },

    setDz_base_ft() {},

    getDz_final_ft() {
      return this.get('dropzone.dz_final_ft');
    },

    setDz_final_ft() {}

  });
  var _default = Dropzone;
  _exports.default = _default;
});