define("spotassist/objects/datetime-parser-object", ["exports", "spotassist/config/environment", "moment"], function (_exports, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DatetimeParser = Ember.Object.extend({
    init() {
      this._super();

      this.set('__interfaces', "com.livewings.spotassist.library.crossdata.IDatetimeParser");
    },

    stringForDatetime(gfsDatetime) {
      var utcMoment = (0, _moment.default)(gfsDatetime).utc();
      var datetime = utcMoment.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      return datetime;
    }

  });
  var _default = DatetimeParser;
  _exports.default = _default;
});