define("spotassist/models/acl", ["exports", "ember-data", "ember-data/model", "ember-data/attr"], function (_exports, _emberData, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    //id: attr(),
    //user: attr(),
    email: (0, _attr.default)(),
    permissions: _emberData.default.hasMany('permission', {
      async: false
    }),

    findPermissions(dropzone, permissionName) {
      var permissions = this.get('permissions').filterBy('permission_name', permissionName).filterBy('type', 'dropzone').filterBy('object_id', dropzone.get('id'));
      return permissions;
    }

  });

  _exports.default = _default;
});