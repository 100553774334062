define("spotassist/routes/auth/registered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    actions: {
      gotoPreRegistered() {
        if (this.get('session.lastUnauthenticatedRoute')) {
          this.transitionTo(this.get('session.lastUnauthenticatedRoute'));
        } else {
          this.transitionTo('index');
        }
      }

    }
  });

  _exports.default = _default;
});