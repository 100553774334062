define("spotassist/utils/dropzone-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = searchDropzoneAsync;

  function searchDropzoneAsync(query, store, cacheContainer) {
    var filter = function (record) {
      var dz_name = record.get('dz_name');
      return dz_name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    };

    return new Ember.RSVP.Promise(function (resolve) {
      if (query.length < 2) {
        resolve([]);
      } else {
        var cache = cacheContainer.get('cache');

        if (!cache) {
          cache = store.findAll('dropzone').then(function (results) {
            cacheContainer.set('cache', results);
            var filteredResults = results.filter(filter);
            resolve(filteredResults);
          });
        } else {
          var filteredResults = cache.filter(filter);
          resolve(filteredResults);
        }
      }
    });
  }
});