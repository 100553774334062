define("spotassist/components/google-mutant-layer", ["exports", "ember-leaflet-google-mutant-layer/components/google-mutant-layer"], function (_exports, _googleMutantLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _googleMutantLayer.default;
    }
  });
});