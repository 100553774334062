define("spotassist/objects/gfs-dated-weather-object", ["exports", "spotassist/objects/gfs-surface-object", "spotassist/objects/gfs-cloud-level-object", "spotassist/objects/gfs-windaloft-level-object", "spotassist/objects/java-date-object"], function (_exports, _gfsSurfaceObject, _gfsCloudLevelObject, _gfsWindaloftLevelObject, _javaDateObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import ENV from "spotassist/config/environment";

  /* global com */
  const GfsDatedWeather = Ember.Object.extend({
    datetime: null,
    ref_datetime: null,
    surface: null,
    clouds: null,
    windsaloft: null,

    init() {
      this._super();

      this.set('__interfaces', "com.livewings.spotassist.library.gfs.IGfsDatedWeather");
    },

    getDatetime() {
      return _javaDateObject.default.create({
        time: this.get('datetime')
      });
    },

    getRef_datetime() {
      return _javaDateObject.default.create({
        time: this.get('ref_datetime')
      });
    },

    getSurface() {
      return _gfsSurfaceObject.default.create(this.get('surface'));
    },

    getClouds() {
      if (!this.get('clouds')) {
        return _gfsCloudLevelObject.default.create([]);
      } else {
        var cloudsArray = this.get('clouds').map(r => _gfsCloudLevelObject.default.create(r));
        return cloudsArray;
      }
    },

    getWindsaloft() {
      if (!this.get('windsaloft')) {
        return _gfsWindaloftLevelObject.default.create([]);
      } else {
        var windsaloftArray = this.get('windsaloft').map(r => _gfsWindaloftLevelObject.default.create(r));
        return windsaloftArray;
      }
    }

  });
  var _default = GfsDatedWeather;
  _exports.default = _default;
});