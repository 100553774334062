define("spotassist/routes/wizard", ["exports", "spotassist/utils/global-error-handler", "spotassist/mixins/trackable-route", "spotassist/mixins/seo-route"], function (_exports, _globalErrorHandler, _trackableRoute, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_trackableRoute.default, _seoRoute.default, {
    actions: {
      openNavAction() {
        this.get('user-settings').openNav();
      },

      closeNavAction() {
        this.get('user-settings').closeNav();
      },

      didTransition() {
        this._super(...arguments);

        this.trackPage();
      }

    },
    beforeModel: function ()
    /*transition*/
    {
      this.get('user-settings').closeNav();
      this.get('user-settings').set('nav-bar-map-extension', false);
    },

    afterModel(model) {
      let title = 'Spot Assist  Widget Wizard';
      let description = 'This wizard will help you generate code for Spot Assist widget on your webpage. Widget shows weather and map of your dropzone.';
      let imageUrl = 'http://www.spotassist.com/wp-content/uploads/2017/02/dropzones-spotassist.png';
      this.setHeadTags(model, title, description, imageUrl);
    },

    model() {
      return null;
    },

    setupController(controller) {
      this.store.findAll('dropzone').then(function (dropzones) {
        controller.set('dropzones', dropzones);
      }, function (error) {
        var message = 'error loading store: dropzone ' + error.message;
        console.error(message);
        (0, _globalErrorHandler.default)(error, message);
      });
    }

  });

  _exports.default = _default;
});