define("spotassist/components/shape-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var _default = Ember.Component.extend({
    //dropzone
    //map
    //targetProperty
    init() {
      this._super();

      var map = this.get('map'); //if(!map.editTools) {

      map.editTools = new L.Editable(map, {}); //}

      var that = this;

      var editingFunction = function (event) {
        that.shapeEditingComplete(event.layer);
        that.set('addMode', false);
      };

      this.set('editingFunction', editingFunction);

      var highlightingFunction = function (event) {
        //that.shapeEditingComplete(event.layer);
        //that.set('addMode', false);
        console.log("highlightingFunction");
        L.DomUtil.addClass(event.target.getElement(), 'out-polygon-highlight');
      };

      this.set('highlightingFunction', highlightingFunction);

      var unhighlightingFunction = function (event) {
        //that.shapeEditingComplete(event.layer);
        //that.set('addMode', false);
        console.log("unhighlightingFunction");
        L.DomUtil.removeClass(event.target.getElement(), 'out-polygon-highlight');
      };

      this.set('unhighlightingFunction', unhighlightingFunction);

      var deletingFunction = function (event) {
        that.shapeDeletingComplete(event.target);
        that.set('deleteMode', false);
      };

      this.set('deletingFunction', deletingFunction);
    },

    willDestroyElement() {
      this.deleteAddListeners();
      this.deleteDeleteListeners();
    },

    deleteAddListeners() {
      var map = this.get('map');
      var editingFunction = this.get('editingFunction');
      map.off('editable:drawing:end', editingFunction);
    },

    deleteDeleteListeners() {
      var map = this.get('map');
      var highlightingFunction = this.get('highlightingFunction');
      var unhighlightingFunction = this.get('unhighlightingFunction');
      var deletingFunction = this.get('deletingFunction');
      map.eachLayer(function (layer) {
        if (layer instanceof L.Polygon) {
          //Do marker specific actions here
          //console.info("poly");
          layer.off('mouseover', highlightingFunction);
          layer.off('mouseout', unhighlightingFunction);
          layer.off('click', deletingFunction);
        }
      });
    },

    actions: {
      startAddMode() {
        this.set('addMode', true);
      },

      startDeleteMode() {
        this.set('deleteMode', true);
      },

      cancelModes() {
        this.set('addMode', false);
        this.set('deleteMode', false);
      }

    },
    addModeStarter: Ember.observer('addMode', function () {
      var map = this.get('map');

      if (this.get('addMode')) {
        map.editTools.startPolygon();
        var editingFunction = this.get('editingFunction');
        map.on('editable:drawing:end', editingFunction);
      } else {
        this.deleteAddListeners();

        if (map.editTools.drawing()) {
          map.editTools.stopDrawing();
        }
      }
    }),
    deleteModeStarter: Ember.observer('deleteMode', function () {
      var map = this.get('map');

      if (this.get('deleteMode')) {
        var highlightingFunction = this.get('highlightingFunction');
        var unhighlightingFunction = this.get('unhighlightingFunction');
        var deletingFunction = this.get('deletingFunction');
        map.eachLayer(function (layer) {
          if (layer instanceof L.Polygon) {
            //Do marker specific actions here
            //console.info("poly");
            layer.on('mouseover', highlightingFunction);
            layer.on('mouseout', unhighlightingFunction);
            layer.on('click', deletingFunction);
          }
        });
      } else {
        this.deleteDeleteListeners();
      }
    }),

    shapeEditingComplete(polygon) {
      console.info('shapeEditingComplete');
      polygon.disableEdit();
      this.get('map').removeLayer(polygon);
      var shape = polygon.toGeoJSON();
      var shape_for_db = JSON.stringify(shape);
      var dropzone = this.get('dropzone');
      var dzOutToAdd = {
        shape_geojson: shape_for_db
      };
      let currentDzOuts = [];
      dropzone.get('dz_outs').forEach(function (dzOut) {
        currentDzOuts.addObject(dzOut);
      });
      currentDzOuts.addObject(dzOutToAdd);
      dropzone.set('dz_outs', currentDzOuts);
    },

    shapeDeletingComplete(polygon) {
      console.info('shapeDeletingComplete');
      var shape = polygon.toGeoJSON();
      var shape_for_db = JSON.stringify(shape);
      var dzOutToRemove = {
        shape_geojson: shape_for_db
      };
      var dropzone = this.get('dropzone');
      let currentDzOuts = [];
      dropzone.get('dz_outs').forEach(function (dzOut) {
        if (dzOut.shape_geojson != dzOutToRemove.shape_geojson) {
          currentDzOuts.addObject(dzOut);
        }
      });
      dropzone.set('dz_outs', currentDzOuts);
    }

  });

  _exports.default = _default;
});