define("spotassist/mixins/trackable-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    metrics: Ember.inject.service(),

    trackPage(options) {
      Ember.run.scheduleOnce('afterRender', this, () => {
        if (options) {
          Ember.get(this, 'metrics').trackPage(options);
        } else {
          //const page = this.get('url');
          const url = this.get('router.url'); //const title = this.getWithDefault('routeName', 'unknown');

          const title = this.get('title');
          Ember.get(this, 'metrics').trackPage({
            url,
            title
          });
        }
      });
    }

  });

  _exports.default = _default;
});