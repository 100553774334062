define("spotassist/controllers/wizard", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    iFrameWidth: 400,
    iFrameHeight: 400,
    dropzone: null,
    currentStep: 'customize',
    actions: {
      selectDropzoneAction(dropzone) {
        this.set('dropzone', dropzone);
      },

      setStep(step) {
        this.set('currentStep', step);

        if (step === 'inspect') {
          this.setupIFrame();
        }
      },

      searchAsync(query) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          if (query.length < 2) {
            resolve([]);
          } else {
            var dropzones = _this.get('dropzones');

            var results = dropzones.filter(function (record) {
              var dz_name = record.get('dz_name');
              return dz_name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            });
            resolve(results);
          }
        });
      }

    },
    isStepCustomize: Ember.computed('currentStep', function () {
      return this.get('currentStep') === 'customize';
    }),
    isStepInspect: Ember.computed('currentStep', function () {
      return this.get('currentStep') === 'inspect';
    }),
    isStepCopy: Ember.computed('currentStep', function () {
      return this.get('currentStep') === 'copy';
    }),
    isStepInspectUnable: Ember.computed('iFrameWidth', 'iFrameHeight', 'dropzone', function () {
      return !this.get('dropzone') || !this.get('iFrameWidth') || !this.get('iFrameHeight');
    }),

    generateIframeCode() {
      var dropzone = this.get('dropzone');
      var iFrameWidth = this.get('iFrameWidth');
      var iFrameHeight = this.get('iFrameHeight');

      if (!dropzone || !iFrameWidth || !iFrameHeight) {
        return "Please enter all parameters 2";
      } else {
        //var iFrameContainer = Ember.$('#iFrameContainer');
        //iFrameContainer.css("height", iFrameHeight+"px");
        var locationSign = "/";

        if (_environment.default.locationType == 'hash') {
          locationSign = "/#";
        } else if (_environment.default.locationType == 'hashbang') {
          locationSign = "/#!";
        }

        var url = locationSign + "/dropzones/" + dropzone.get('id') + '\n?layout=tabs';
        var html = "<iframe frameborder='0' \n src='" + url + "' \nwidth='" + iFrameWidth + "px' \nheight='" + iFrameHeight + "px' />";
        return html;
      }
    },

    setupIFrame() {
      var iFrameHtml = this.generateIframeCode();
      var iFrameContainer = Ember.$('#iFrameContainer');
      iFrameContainer.html(iFrameHtml);
    },

    generatedCode: Ember.computed('dropzone', 'iFrameWidth', 'iFrameHeight', function () {
      var iFrameHtml = this.generateIframeCode();
      return iFrameHtml;
      /*
      var iFrameContainer = Ember.$('#iFrameCode');
      var html = "<figure class='highlight'><pre><code class='language-html' data-lang='html'>" + iFrameHtml+"</code></pre></figure>"
      	iFrameContainer.html(""+html);
      */
    })
  });

  _exports.default = _default;
});